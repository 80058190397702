<template>
  <div>
    <Card>
      <template v-slot:header>{{ $t('2fafe31') }}</template>
      <template v-slot:actions>
        <el-button
          v-if="showCancelApplyButton"
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="handleCancelApplyClick"
        >
          <span
            style="color: rgba(0, 164, 255, 1); font-size: 14px"
          >{{ $t('5be0c19') }}</span>
        </el-button>
        <el-button
          v-if="showCancelCooperationButton"
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="handleCancelCooperationClick"
        >
          <span
            style="color: rgba(0, 164, 255, 1); font-size: 14px"
          >{{ $t('3e5fd7d') }}</span>
        </el-button>
      </template>
      <DisplayCard :data="data" />
      <div class="button-box">
        <ActionButton v-if="showComButton" @click="handleComClick">
          {{ $t('92c8446') }}
        </ActionButton>
        <ActionButton v-if="showCooperButton" @click="handleCooperClick">
          {{ $t('b5a5f55') }}
        </ActionButton>
        <ActionButton v-if="showCompleteButton" @click="handleCompleteClick">
          {{ $t('c3c076c') }}
        </ActionButton>
      </div>
    </Card>
  </div>
</template>

<script>
import { Message } from "element-ui"

import Card from "@/components/Card"
import ActionButton from "@/components/ActionButton"
import DisplayCard from "../displayCard/index"

import api from "@/api/request"

export default {
  name: "RequestDetail",
  components: {
    Card,
    DisplayCard,
    ActionButton,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    showComButton() {
      return this.data.status === "TO_BE_COMMUNICATED"
    },
    showCooperButton() {
      return this.data.status === "SERVICE_COMMUNICATED"
    },
    showCompleteButton() {
      return this.data.status === "COOPERATION"
    },
    showCancelApplyButton() {
      return (
        this.data.status === "TO_BE_COMMUNICATED" ||
        this.data.status === "SERVICE_COMMUNICATED"
      )
    },
    showCancelCooperationButton() {
      return this.data.status === "COOPERATION"
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    handleComClick() {
      api.setCommunicated(this.data.id).then((res) => {
        if (res) {
          this.getDetail()
        }
      })
    },
    handleCooperClick() {
      api.setCooperation(this.data.id).then((res) => {
        if (res) {
          this.getDetail()
        }
      })
    },
    handleCompleteClick() {
      api.setComplete(this.data.id).then((res) => {
        if (res) {
          this.getDetail()
        }
      })
    },
    handleCancelCooperationClick() {
      api.cancelOrder(this.data.id).then((res) => {
        if (res) {
          this.getDetail()
          Message({
            message: this.$t('70a4373').toString(),
            type: "success",
            duration: 2 * 1000,
          })
        }
      })
    },
    handleCancelApplyClick() {
      api.cancelApply(this.data.id).then((res) => {
        if (res) {
          this.getDetail()
          Message({
            message: this.$t('9b65e9b').toString(),
            type: "success",
            duration: 2 * 1000,
          })
        }
      })
    },
    getDetail() {
      const id = this.$route.query.id
      if (id) {
        api.getCooperationDetail(id).then((res) => {
          this.data = res
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.button-box {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
